const elevationButtonStyle = {
  width: '34px',
  height: '34px',
  left: '0',
  marginTop: '0',
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  fontSize: '3rem',
  position: 'absolute',
  top: '120px',
  zIndex: 999,
  padding: '5px',
  background: 'lightgreen'
}

const elevationButtonStyleActive = {
  fill: 'red'
}

const elevationButtonStyleAnimation = {
  width: '17px',
  height: '17px',
  border: '1px solid #fff',
  borderRadius: '50%',
  background: '#2a93ee',
  animation: 'borderPulse 2s infinite'
}

//   @keyframes borderPulse {
//     0% {
//       box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
//     }

//     70% {
//       box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
//     }

//     100% {
//       box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
//     }
//   }

export { elevationButtonStyle, elevationButtonStyleActive, elevationButtonStyleAnimation }
