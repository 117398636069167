import React from 'react'
import { useState } from 'react'

import { Outlet } from 'react-router-dom'
import { useParams } from 'react-router'

import { useUserLocation } from '../hooks/useUserLocation'
import { useTrackData } from '../hooks/useTrackData'
import { useElevationProfile } from '../hooks/useElevationProfile'
import useDrawerConrols from '../hooks/useDrawerControls'

const LoadData = () => {
  const [trackImage, setTrackImage] = useState(null)
  const [trackName, setTrackName] = useState(null)
  const [trackCoordinates, setTrackCoordinates] = useState(null)
  const [fileName, setFileName] = useState(null)
  const { lat, long, isLoading, initMap } = useUserLocation()
  const { completedTracks, wishlistTracks, docTracks, tracksLoaded } = useTrackData()
  const { isElevationProfileOpen, handleCloseElevationProfile, handleOpenElevationProfile } = useElevationProfile(
    trackName,
    trackCoordinates
  )
  const [clickLoading, setClickLoading] = useState(false)
  const { trackSlug, details } = useParams()

  const { isDrawerOpen, handleCloseDrawer, handleOpenDrawer } = useDrawerConrols()

  return (
    <Outlet
      context={{
        initMap,
        lat,
        long,
        isLoading,
        tracksLoaded,
        completedTracks,
        wishlistTracks,
        docTracks,
        trackSlug,
        clickLoading,
        trackImage,
        setTrackImage,
        trackName,
        setTrackName,
        fileName,
        setFileName,
        setClickLoading,
        details,
        trackCoordinates,
        setTrackCoordinates,
        isElevationProfileOpen,
        handleCloseElevationProfile,
        handleOpenElevationProfile,
        isDrawerOpen,
        handleCloseDrawer,
        handleOpenDrawer
      }}
    />
  )
}

export default LoadData
