import { useMap } from 'react-leaflet/hooks'
import { useCallback, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

const OpenToTrack = ({ trackSlug }) => {
  const { clickLoading, setTrackName, setTrackImage, setTrackCoordinates } = useOutletContext()

  const map = useMap()

  const flyToTheTrack = useCallback(() => {
    map.eachLayer((layer) => {
      if (layer.feature !== undefined && layer.feature.properties.slug === trackSlug) {
        const layerCoors = layer.getLatLngs()

        setTrackName(layer.feature.properties.name)
        setTrackImage(layer.feature.properties.image)
        setTrackCoordinates(layerCoors)

        map.setView(layerCoors[0], 12)
        layer.setStyle({
          color: 'red',
          opacity: 1
        })
        layer.bringToFront()
      }
    })
  }, [map, trackSlug, setTrackName, setTrackImage, setTrackCoordinates])

  useEffect(() => {
    if (clickLoading === false) {
      flyToTheTrack()
    }
  }, [flyToTheTrack, clickLoading])
}

export default OpenToTrack
