import React, { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'

import TracksOnMap from './TracksOnMap/TracksOnMap'
import OpenToTrack from './OpenToTrack/OpenToTrack'
import UserLocation from './UserLocation/UserLocation'
import MetaHeader from './MetaHeader/MetaHeader'
import CurrentLocation from './CurrentLocation/CurrentLocation'

import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'

import L from 'leaflet'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import ElevationButton from './ElevationButton/ElevationButton'
// import MenuButton from "./MenuButton/MenuButton";
// import DrawerPanel from "./Drawer/Drawer";

const backdropStyle = {
  color: 'black',
  zIndex: 100,
  backgroundColor: '#eeeeee'
}

const Home = () => {
  const {
    initMap,
    lat,
    long,
    isLoading,
    completedTracks,
    wishlistTracks,
    trackSlug,
    trackImage,

    trackName
  } = useOutletContext()

  const canvasRenderer = useMemo(() => L.canvas({ tolerance: 10 }), [])

  const showMap = useMemo(() => initMap && completedTracks && wishlistTracks, [initMap, completedTracks, wishlistTracks])

  return (
    <div className='wraper-box'>
      <MetaHeader image={trackImage} trackName={trackName} />
      <UserLocation />

      {isLoading && !showMap && (
        <Backdrop sx={backdropStyle} open={true}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}

      {showMap && (
        <MapContainer id='leaflet-map' center={[lat, long]} zoom={7} zoomControl={false} scrollWheelZoom={false} renderer={canvasRenderer}>
          <TileLayer
            minZoom={7}
            maxZoom={12}
            attribution="Images sourced from <a href='https://www.linz.govt.nz/' target='_blank' title='LINZ'>LINZ</a>"
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50798/EPSG:3857/{z}/{x}/{y}.png'
          />
          <TileLayer
            minZoom={13}
            maxZoom={16}
            attribution=''
            url='https://tiles-cdn.koordinates.com/services;key=78ddbbf18792437baac11a81c1805f72/tiles/v4/layer=50767/EPSG:3857/{z}/{x}/{y}.png'
          />
          <TracksOnMap />
          <OpenToTrack trackSlug={trackSlug} />
          <ZoomControl position='topleft' />
          <CurrentLocation />
          {/* <MenuButton /> */}
          {/* <DrawerPanel /> */}
          <ElevationButton trackSlug={trackSlug} trackName={trackName} />
        </MapContainer>
      )}
    </div>
  )
}

export default Home
