import { useEffect, useCallback, useRef } from 'react'
import { useMap } from 'react-leaflet'
import styles from '../../styles/location-button.module.css'
import L from 'leaflet'
import PropTypes from 'prop-types'

const campingIcon = L.icon({
  iconUrl:
    'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZWxsaXBzZSBzdHlsZT0iZmlsbDogYmx1ZTsgc3Ryb2tlOiByZ2IoMCwgMCwgMCk7IHN0cm9rZS13aWR0aDogMTA7IiBjeD0iNTAiIGN5PSI1MCIgcng9IjQwIiByeT0iNDAiPjwvZWxsaXBzZT4KPC9zdmc+',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
  popupAnchor: [0, 0],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  className: styles.iconPulse
})

const TrackWaypoint = ({ waypoints }) => {
  const map = useMap()
  const points = useRef([])

  const addWaypoint = useCallback(() => {
    if (waypoints) {
      if (points.current.length > 0) {
        points.current.map((item) => {
          item.removeFrom(map)
          return null
        })
        points.current = []
      }

      waypoints.map((waypoint) => {
        const latlng = L.latLng(waypoint.coordinates[1], waypoint.coordinates[0])

        const pointMarker = L.marker(latlng, {
          icon: campingIcon,
          titile: waypoint.name,
          alt: waypoint.name
        })
        pointMarker.bindTooltip(waypoint.name)
        pointMarker.addTo(map)

        return points.current.push(pointMarker)
      })
    }
  }, [map, waypoints])

  useEffect(() => {
    addWaypoint()
  }, [addWaypoint])

  return null
}

TrackWaypoint.propTypes = {
  waypoints: PropTypes.array
}

export default TrackWaypoint
