import { useState, useEffect, useCallback } from 'react'

import L from 'leaflet'

const useUserLocation = () => {
  const [lat, setLat] = useState(null)
  const [long, setLong] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [initMap, setInitMap] = useState(false)

  /* GET USER LOCATION */
  const geolocationAPI = navigator.geolocation

  const getUserCoordinates = useCallback(async () => {
    //console.log('start user location')

    if (!geolocationAPI) {
      console.log('no geo api')
      setLat(-39.287205)
      setLong(175.603006)
      setIsLoading(false)
      setInitMap(true)
    } else {
      geolocationAPI.getCurrentPosition(
        async (position) => {
          const { coords } = position

          //console.log(position)

          const corner1 = L.latLng(-46.90753527990583, 178.48493223438592)
          const corner2 = L.latLng(-34.36434316282625, 166.00446368145288)

          const userLocation = L.latLng(coords.latitude, coords.longitude)

          const bounds = L.latLngBounds(corner1, corner2)

          if (bounds.contains(userLocation)) {
            setLat(coords.latitude)
            setLong(coords.longitude)
            setIsLoading(false)
            setInitMap(true)
          } else {
            setLat(-39.287205)
            setLong(175.603006)
            setIsLoading(false)
            setInitMap(true)
          }
        },
        async (error) => {
          console.log('error with getting your geo', error)
          setLat(-39.287205)
          setLong(175.603006)
          setIsLoading(false)
          setInitMap(true)
        }
      )
    }
  }, [geolocationAPI])

  useEffect(() => {
    getUserCoordinates()
  }, [lat, long, isLoading, initMap, getUserCoordinates])

  return { lat, long, isLoading, initMap }
}

export { useUserLocation }
export default useUserLocation
