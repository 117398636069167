// import { useNavigate } from "react-router-dom";
// import { mdiDownload } from "@mdi/js";

// const styles = {
//   image: { width: "100%", height: "auto", display: "block" },
//   textWrap: { padding: "10px 10px" },
//   button: {
//     background: "none",
//     border: "none",
//     display: "inline-flex",
//     borderRadius: " 50%",
//     width: "40px",
//     height: "40px",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: " 0",
//     margin: "0",
//   },
// };

const useTrackPopup = () => {
  // const navigate = useNavigate();

  const createPopup = (layer, trackImageUrl, trackName, trackDuration, trackDistance, fileName) => {
    const fileUrl = process.env.PUBLIC_URL + `/data-gpx/` + fileName

    //wrapper
    const wrapper = document.createElement('div')

    //image
    const trackImage = document.createElement('img')
    trackImage.src = trackImageUrl
    trackImage.alt = trackName
    trackImage.style.display = 'block'
    trackImage.style.width = '100%'
    trackImage.style.height = 'auto'
    trackImage.style.border = 'none'

    wrapper.appendChild(trackImage)

    //textBox
    const textBox = document.createElement('div')
    textBox.style.padding = '10px 10px'
    textBox.style.fontSize = '12px'
    textBox.style.color = '#555555'
    textBox.innerHTML = `
    <div style="font-size: 14px; font-weight: 700; color: #333333; padding-bottom:5px;">${trackName}</div>
    <div>Duration: ${trackDuration} days</div>
    <div style="padding-bottom: 5px;">Distance: ~${Number(trackDistance / 1000).toFixed(2)} kms</div>
    <div><a href="${fileUrl}" title="Download GPX" target="_blank">Download GPX</a></div>`
    wrapper.appendChild(textBox)

    // //download button
    // const downloadBtn = document.createElement("a");
    // downloadBtn.innerHTML = "";
    // downloadBtn.id = "buttonId";

    // // downloadBtn.style.background = "none";
    // // downloadBtn.style.border = "none";
    // downloadBtn.style.display = "inline-flex";
    // //downloadBtn.style.borderRadius = "50%";
    // // downloadBtn.style.width = "40px";
    // // downloadBtn.style.height = "40px";
    // downloadBtn.style.alignItems = "center";
    // downloadBtn.style.justifyContent = "center";
    // downloadBtn.style.padding = "0";
    // downloadBtn.style.margin = "0";

    // textBox.appendChild(downloadBtn);

    // //downloadIcon
    // const downloadIconSVG = document.createElementNS(
    //   "http://www.w3.org/2000/svg",
    //   "svg"
    // );
    // downloadIconSVG.setAttributeNS(null, "viewBox", "0 0 24px 24px");
    // downloadIconSVG.setAttributeNS(null, "width", "24px");
    // downloadIconSVG.setAttributeNS(null, "height", "24px");
    // downloadIconSVG.style.display = "block";
    // const downloadIconPath = document.createElementNS(
    //   "http://www.w3.org/2000/svg",
    //   "path"
    // );

    // downloadIconPath.setAttributeNS(null, "d", mdiDownload);

    // downloadIconSVG.appendChild(downloadIconPath);

    // downloadBtn.prepend(downloadIconSVG);

    return layer.bindPopup(wrapper, { closeButton: false, offset: [0, -2] })
  }
  return { createPopup }
}

export default useTrackPopup
