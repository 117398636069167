import { useEffect, useState, useCallback } from 'react'

const useTrackData = () => {
  const [completedTracks, setCompletedTracks] = useState(null)
  const [wishlistTracks, setWishlistTracks] = useState(null)
  const [docTracks, setDocTracks] = useState(null)
  const [tracksLoaded, setTracksLoaded] = useState(false)

  // Fetch the JSON data from the completed tracks.
  const fetchComplitedTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/combined.json')
      const jsonData = await response.json()
      setCompletedTracks(jsonData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // Fetch the JSON data from the wishlist tracks.
  const fetchWishlistTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/wishlist.json')
      const jsonData = await response.json()
      setWishlistTracks(jsonData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // Fetch the JSON data from the DOC tracks.
  const fetchDocTracks = useCallback(async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/data-tracks/doc.json')
      const jsonData = await response.json()
      setDocTracks(jsonData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    fetchComplitedTracks()
  }, [fetchComplitedTracks])

  useEffect(() => {
    fetchWishlistTracks()
  }, [fetchWishlistTracks])

  useEffect(() => {
    fetchDocTracks()
  }, [fetchDocTracks])

  useEffect(() => {
    if (completedTracks && wishlistTracks && docTracks) {
      setTracksLoaded(true)
    }
  }, [completedTracks, wishlistTracks, docTracks])

  return { completedTracks, wishlistTracks, docTracks, tracksLoaded }
}

export { useTrackData }
export default useTrackData
